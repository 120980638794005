import React from 'react';
import AnimationControl from '../AnimationControl';
import ImageNavigation from '../ImageNavigation';
import styles from './styles.module.css';

interface ControlPanelProps {
  isPlaying: boolean;
  setIsPlaying: (newState: boolean) => void;
  numberOfImages: number;
  currentIndex: number;
  setCurrentIndex: (newIndex: number) => void;
}

const ControlPanel: React.FC<ControlPanelProps> = ({
  isPlaying,
  setIsPlaying,
  numberOfImages,
  currentIndex,
  setCurrentIndex,
}: ControlPanelProps) => {
  return (
    <div className={styles.controlPanel}>
      <ImageNavigation
        numberOfImages={numberOfImages}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        setIsPlaying={setIsPlaying}
      />
      <div className={styles.animationControl}>
        <AnimationControl isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
      </div>
    </div>
  );
};

export default ControlPanel;
