// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import './mapbox-overwrites.css';

export type Coordinates = number[][];

enum SourceId {
  Edge = 'edge',
  Radar = 'radar',
}

enum StorageKey {
  Lng = 'lng',
  Lat = 'lat',
  Zoom = 'zoom',
}

export default class RadarMap extends mapboxgl.Map {
  constructor(container: string | HTMLElement) {
    super({
      container,
      accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      style: 'mapbox://styles/mapbox/light-v10?optimize=true',
      center: [
        Number(localStorage.getItem(StorageKey.Lng) || 26.25),
        Number(localStorage.getItem(StorageKey.Lat) || 65),
      ],
      zoom: Number(localStorage.getItem(StorageKey.Zoom) || 4),
      minZoom: 3,
      maxZoom: 15,
      attributionControl: false,
    });
    this.addControl(new mapboxgl.AttributionControl({ compact: true }), 'bottom-left');
    this.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-left');
    this.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }),
      'top-left',
    );
    this.on('moveend', () => {
      const center = this.getCenter();
      localStorage.setItem(StorageKey.Lng, center.lng.toString());
      localStorage.setItem(StorageKey.Lat, center.lat.toString());
      localStorage.setItem(StorageKey.Zoom, this.getZoom().toString());
    });
  }

  public updateRadarEdge = (url: string, coordinates: Coordinates): void => {
    this.updateImageOverlay(url, coordinates, SourceId.Edge);
  };

  public updateRadarImage = (url: string, coordinates: Coordinates): void => {
    this.updateImageOverlay(url, coordinates, SourceId.Radar, {
      'raster-opacity': 0.7,
      'raster-fade-duration': 0,
    });
  };

  public preloadImages = async (urls: string[], callback?: () => void): Promise<void> => {
    await Promise.all(urls.map((url) => this.preloadImage(url)));
    callback?.();
  };

  private preloadImage = async (url: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      this.loadImage(url, (err, res) => {
        if (err) reject();
        if (res) resolve();
      });
    });
  };

  private updateImageOverlay = (
    url: string,
    coordinates: Coordinates,
    sourceId: string,
    paint?: mapboxgl.RasterPaint,
  ): void => {
    const source = this.getSource(sourceId) as mapboxgl.ImageSource;
    if (source) {
      source.updateImage({ url, coordinates });
    } else {
      this.addImageOverlay(url, coordinates, sourceId, paint);
    }
  };

  private addImageOverlay = (
    url: string,
    coordinates: Coordinates,
    sourceId: string,
    paint?: mapboxgl.RasterPaint,
  ): void => {
    this.addSource(sourceId, {
      type: 'image',
      url,
      coordinates,
    });
    this.addLayer({
      id: `${sourceId}Layer`,
      source: sourceId,
      type: 'raster',
      paint: paint || {},
    });
  };
}
