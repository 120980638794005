import React from 'react';
import { Slider } from '@material-ui/core';
import styles from './styles.module.css';

interface ImageNavigationProps {
  numberOfImages: number;
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  setIsPlaying: (newState: boolean) => void;
}

const ImageNavigation: React.FC<ImageNavigationProps> = ({
  numberOfImages,
  currentIndex,
  setCurrentIndex,
  setIsPlaying,
}: ImageNavigationProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (_event: React.ChangeEvent<{}>, value: number | number[]): void => {
    setCurrentIndex(value as number);
    setIsPlaying(false);
  };

  return (
    <Slider
      className={styles.imageNavigation}
      max={numberOfImages - 1}
      value={currentIndex}
      marks
      orientation="vertical"
      onChange={handleChange}
    />
  );
};

export default ImageNavigation;
