import { useState } from 'react';
import { subHours, addMinutes } from 'date-fns';
import { Coordinates } from '../components/Map/radarMap';

export interface RadarImage {
  url: string;
  timestamp: string;
}

export interface RadarImages {
  coordinates: Coordinates;
  edgeUrl: string;
  images: RadarImage[];
}

const getLatestValidTime = (): Date => {
  const date = new Date();
  // Round down to nearest 15min using 6min offset
  const minutes = Math.floor((date.getMinutes() - 6) / 15) * 15;
  date.setMinutes(minutes, 0, 0);
  return date;
};

// TODO: fetch image configurations from api
const getImages = (stepLength: number): RadarImage[] => {
  const images: RadarImage[] = [];
  const baseUrl = process.env?.NODE_ENV === 'development' ? 'http://localhost:3001' : '';
  const endTime = getLatestValidTime();
  let startTime = subHours(endTime, 12);
  while (startTime <= endTime) {
    const timestamp = startTime.toISOString();
    images.push({
      timestamp,
      url: `${baseUrl}/api/precipitation-rate/${timestamp}`,
    });
    startTime = addMinutes(startTime, stepLength);
  }
  return images;
};

const useRadarImages = (): RadarImages => {
  const [images] = useState<RadarImage[]>(getImages(15));
  const [coordinates] = useState<Coordinates>([
    // 57.99, 17.74, 69.34, 33.40
    [17.74, 69.34],
    [33.4, 69.34],
    [33.4, 57.99],
    [17.74, 57.99],
  ]);
  const [edgeUrl] = useState<string>('edge.png');

  // TODO: follow settings with useEffect

  return {
    coordinates,
    edgeUrl,
    images,
  };
};

export default useRadarImages;
