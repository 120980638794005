import React, { useEffect, useMemo, useState, useCallback } from 'react';
import Map from '../Map';
import Timestamp from '../Timestamp';
import ControlPanel from '../ControlPanel';
import useRadarImages from '../../hooks/useImages';
import styles from './styles.module.css';

const RainRadar: React.FC = () => {
  const { coordinates, edgeUrl, images } = useRadarImages();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  /**
   * Creats image animation.
   */
  useEffect(() => {
    if (isPlaying) {
      const newIndex = (currentIndex + 1) % images.length;
      const timeout = setTimeout(() => setCurrentIndex(newIndex), 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
    return undefined;
  }, [isPlaying, currentIndex, images]);

  const preloadUrls = useMemo(() => {
    return images?.map((img) => img.url);
  }, [images]);

  const onPreload = useCallback(() => {
    setIsPlaying(true);
  }, []);

  return (
    <div className={styles.rainRadar}>
      <Map
        edgeUrl={edgeUrl}
        coordinates={coordinates}
        currentImageUrl={images?.[currentIndex]?.url}
        preloads={preloadUrls}
        onPreload={onPreload}
      />
      <div className={styles.timestamp}>
        <Timestamp timestamp={images[currentIndex]?.timestamp} />
      </div>
      <div className={styles.controlPanel}>
        <ControlPanel
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          numberOfImages={images.length}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      </div>
    </div>
  );
};

export default RainRadar;
