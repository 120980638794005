import React, { useEffect, useRef, useState } from 'react';
import { RadarImages } from '../../hooks/useImages';
import RadarMap from './radarMap';
import styles from './styles.module.css';

interface MapProps extends Partial<RadarImages> {
  currentImageUrl: string | undefined;
  preloads?: string[];
  onPreload?: () => void;
}

const Map: React.FC<MapProps> = ({
  edgeUrl,
  coordinates,
  currentImageUrl,
  preloads,
  onPreload,
}) => {
  const [map, setMap] = useState<RadarMap>();
  const mapContainer = useRef<HTMLDivElement>(null);

  /**
   * Creates the map
   */
  useEffect(() => {
    if (!map && mapContainer?.current) {
      const radarMap = new RadarMap(mapContainer.current);
      radarMap.on('load', () => {
        setMap(radarMap);
      });
    }
    return (): void => {
      map?.remove();
    };
  }, [map]);

  /**
   * Updates radar edge.
   */
  useEffect(() => {
    if (map && edgeUrl && coordinates) {
      map.updateRadarEdge(edgeUrl, coordinates);
    }
    return undefined;
  }, [map, edgeUrl, coordinates]);

  /**
   * Updates radar image.
   */
  useEffect(() => {
    if (map && coordinates && currentImageUrl) {
      map.updateRadarImage(currentImageUrl, coordinates);
    }
    return undefined;
  }, [map, currentImageUrl, coordinates]);

  useEffect(() => {
    if (map && preloads) {
      map.preloadImages(preloads, onPreload);
    }
    return undefined;
  }, [map, preloads, onPreload]);

  return <div ref={mapContainer} className={styles.map} />;
};

Map.defaultProps = {
  preloads: [],
  onPreload: () => {},
};

export default Map;
