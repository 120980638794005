import React from 'react';
import { format, isValid } from 'date-fns';
import fi from 'date-fns/esm/locale/fi';
import styles from './styles.module.css';

interface TimestampProps {
  timestamp: string;
}

const Timestamp: React.FC<TimestampProps> = ({ timestamp }: TimestampProps) => {
  const date = new Date(timestamp);
  return (
    <div className={styles.timestamp}>
      {isValid(date) ? format(date, 'eeeeee dd.MM. HH:mm', { locale: fi }) : ''}
    </div>
  );
};

export default Timestamp;
