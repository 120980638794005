import React, { useEffect, useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { PlayArrow, Pause } from '@material-ui/icons';
import styles from './styles.module.css';

interface AnimationControlProps {
  isPlaying: boolean;
  setIsPlaying: (newState: boolean) => void;
}

const AnimationControl: React.FC<AnimationControlProps> = ({
  isPlaying,
  setIsPlaying,
}: AnimationControlProps) => {
  const toggleIsPlaying = useCallback(() => {
    setIsPlaying(!isPlaying);
  }, [isPlaying, setIsPlaying]);

  useEffect(() => {
    const onKeyUp = (event: KeyboardEvent): void => {
      if (event.key === ' ') {
        toggleIsPlaying();
      }
    };
    window.addEventListener('keyup', onKeyUp);
    return (): void => {
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [toggleIsPlaying]);

  return (
    <IconButton className={styles.animationControl} onClick={toggleIsPlaying}>
      {isPlaying ? <Pause /> : <PlayArrow />}
    </IconButton>
  );
};

export default AnimationControl;
